@import '~antd/dist/antd.css';

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Icon-container {
    height: 50px;
    width: 50px;
    margin: auto;
}

.View-container {
    display: flex;
    flex-direction: row;
}
.Sidebar-container {
    display: flex;
    flex-direction: column;
}
.ShowAll-container {
    padding: 20px;
}
.Table-container {
    width: 90%;
}
.Tabs-container {
    /* width: 250px; */
    width: 90%;
    align-content: left;
}
